import React from "react";
import Login from "./page/Login";
import Sidebar from "./page/Sidebar";
import {
  Navigate,
  Route,
  Routes,
  Redirect,
  useNavigate,
} from "react-router-dom";
import Home from "./page/Home";
import Users from "./page/Users";
import Orders from "./page/Orders";
import LoginProtected from "./components/LoginProtected";
import DashboardProtected from "./components/DashboardProtected";
import Notfound from "./page/Notfound";
import Completed from "./page/Completed";
import { Toaster } from "react-hot-toast";
import RevolutOrders from "./page/RevolutOrders";
import RevolutCompletedOrders from "./page/RevolutCompletedOrders";
import StripePendingOrders from "./page/StripePendingOrders";
import StripeCompletedOrders from "./page/StripeCompletedOrders";



const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<DashboardProtected />}>
          <Route path="/" element={<Sidebar />}>
            <Route index element={<Home />} />
            <Route path="/users" element={<Users />} />
            <Route path="/pending-orders" element={<StripePendingOrders />} />
            <Route path="/comspleted-orders" element={<StripeCompletedOrders />} />
            <Route path="/bank-orders" element={<RevolutOrders />} />
            <Route path="/completed-bank-orders" element={<RevolutCompletedOrders />} />
          </Route>
        </Route>
        <Route element={<LoginProtected />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="*" element={<Notfound />} />
      </Routes>
      <Toaster position="top-center"/>
    </>
  );
};

export default App;
